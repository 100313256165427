
.blogs{
    margin-bottom: 4rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1.75rem;
    justify-content: center;
    align-items: center;
}

.blogs .post{
    position: relative;
    overflow: hidden;
    background-color: var(--white);
    border-radius: 5px;
    width: 100%;
    height: 100%;
    min-width: 290px;
}

.blogs .post .post-image{
    width: 100%;
    height: 180px;
    position: relative;
}

.blogs .post .post-image img{
    z-index: 1;
    position: absolute;
    object-fit: cover;
    object-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.blogs .post .post-details{
    padding: .8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.blogs .post-details .author-image{
    top: 30%;
    width: 70px;
    height: 70px;
    position: absolute;
}

.blogs .post-details .author-image img{
    border-radius: 50%;
    z-index: 1;
    position: absolute;
    object-fit: cover;
    object-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.blogs .post .post-details span{
    margin-bottom: .5rem;
}

.blogs .post .post-details .read{
    color: var(--white);
    background-color: var(--primary-300);
}

.blogs .post .post-details h5{
    margin-top: 2rem;
    margin-bottom: .5rem;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.616);
}

.blogs .post .post-details h3{
    text-wrap: nowrap;
    font-weight: 300;
    margin-bottom: .5rem;
    color: var(--primary-700);
}

.blogs .post .post-details p{
    font-size: 14px;
}

.blogs .post .post-details .read-more{
    margin-bottom: .5rem;
    padding: .7rem 1.5rem;
    font-size: 14px;
    background-color: var(--primary-300);
    color: var(--white);
}

@media (min-width:768px) {
    .blogs .post{
        flex-basis: 33.33%;
    }
}

@media (min-width:1024px) {
    .blogs .post{
        flex-basis: 25%;
    }
}