
.jumbotron{
    background-image: url('/public/img/home.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    position: relative;
    z-index: 2;
}

.jumbotron .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

.jumbotron .content{
    padding: 0 1rem;
    position: relative;
    bottom: 50px;
    z-index: 2;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* text-align: center; */
}

.jumbotron .content h2{
    font-size: 24px;
    max-width: 960px;
    font-family: var(--heading-font);
    line-height: 1.5;
    color: var(--white);
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    text-transform: uppercase;
    margin-bottom: 2rem;
}
.jumbotron .content h2 span{
    text-align: center;
    display: block;
}

.jumbotron .content button{
    width: 100%;
    max-width: 200px;
    background: none;
    border: 2.5px solid var(--primary-500);
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-500);
    transition: all 200ms ease-in;
}

.jumbotron .content button:hover{
    background-color: var(--primary-500);
    color: var(--white);
}

.services{
    background-color: hsla(0, 0%, 0%, 0.75);
}

.services-grid{
    margin: auto;
    display:grid;
    justify-items: center;
    align-content: center;
}

.services-grid .service{
    /* border-bottom: 1px solid var(--white); */
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    text-align: center;
}
.services-grid .service:last-of-type{
    border: none;
}

.services-grid .service h4{
    text-transform: uppercase;
    color: var(--white);
}

.services-grid .service h5{
    color: var(--white);
    font-weight: 300;
}

.services-grid .service p{
    color: var(--white);
}

.call-for-action{
    background-color: #474343;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.call-for-action h2{
    text-align: center;
    color: var(--white);
}

.call-for-action .cfa-btn{
    max-width: 400px;
    width: 100%;
    border: 1px solid var(--white);
    background: none;
    color: var(--white);
    font-weight: 500;
    font-size: 17px;
}

.testimonials{
    padding: 3rem 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.testimonials-wrapper{
    padding-bottom: 3rem;
    display: grid;
    gap: 2.5rem;
    justify-content: space-between;
}

.testimonial-btn{
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    max-width: 250px;
    width: 100%;
    border: 1px solid white;
    background:none;
    color: white;
    font-size: 16px;
}

.about{
    padding: 3rem 0;
}

.about .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #cecece;
}

.about .content:last-of-type{
    border: none;
}

.about .content h4{
    text-align: center;
    color: var(--primary-700);
}
.about .content p{
    max-width: 1200px;
    text-align: center;
}

.partners{
    background-color: rgba(0, 0, 0, 0.75);
}

.partners-wrapper{
    margin: auto;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;

}

@media (min-width: 640px) {
    .services-grid{
        grid-template-columns: repeat(3,1fr);
    }

    /* .services-grid .service{
        border: none;
        border-right: 1px solid var(--white);
    } */

    .jumbotron .content h2{
        font-size: 28px;
    }

    .testimonials-wrapper{
        grid-template-columns: repeat(2,1fr);
    }

    .partners-wrapper{
        max-width: 100%;
        column-gap: 1rem;
    }
}

@media (min-width: 768px) {

    .jumbotron .content{
        bottom: 0;
    }
    .jumbotron .content button{
        width: 100%;
        max-width: 400px;
        font-size: 24px;
    }
    .jumbotron .content h2{
        font-size: 32px;
    }

    .testimonials-wrapper{
        grid-template-columns: repeat(3,1fr);
    }
}

@media (min-width: 1024px) {

    .testimonials-wrapper{
        grid-template-columns: repeat(4,1fr);
    }
}

