
.testimonial{
    width: 100%;
}

.stars{
    color: #FFF47F;
}

.testimonial .testimonial-container{
    padding: 1rem;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.10);
}

.testimonial-container h5{
    font-size: 15px;
    margin-bottom: 0.25rem;
    color: white;
}

.testimonial-container p{
    color: white;
    font-size: 15px;
}

.testimonial-author{
    margin-top: 0.8rem;
    display: flex;
    column-gap: 0.5rem;
}

.testimonial-author h6{
    font-size: 14px;
    font-weight: 500;
    color: white;
}

.testimonial-author span{
    font-size: 12px;
    color: white;
}

.testimonial-author .author-image{
    position: relative;
    width: 36px;
    height: 36px;    
}

.testimonial-author .author-image img{
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}