
.contact-wrapper{
    max-width: 1200px;
    margin: auto;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    column-gap: 10rem;
    row-gap: 3rem;
}

.contact-info{
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
}

.contact-info .info{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.contact-info .info .info-title{
    margin-bottom: .8rem;
    font-weight: 700;
    color: var(--primary-500);
    text-transform: uppercase;
}

.contact-info span{
    display: block;
    margin-bottom:.5rem;
    font-size:16px;
    
}
.contact-info p,.contact-info a{
    margin: 0;
    font-weight: 500;
    font-size: 18px;

}
.contact-form{
    flex: 1;
    max-width: 640px;
}
.form-heading{
    border-bottom: 1px solid darkgrey;
    margin-bottom: 2rem;
}

.form-heading h3{
    font-weight: 300;
}

.submit{
    display: flex;
    justify-content: center;
    font-size: 17px;
    padding: .7rem 2rem;
    margin-top: 1rem;
    margin-bottom: 5rem;
    background: none;
    color: var(--primary-500);
    border: 2px solid var(--primary-500);
    transition: all 200ms ease-in;

}

.submit:hover{
    background-color: var(--primary-500);
    color: var(--white);
}

@media (min-width:640px) {

    .submit{
        font-size: 18px;
        max-width: 170px;
        width: 100%;
    
    }
}

@media (min-width:786px) {
    .contact-wrapper{
        flex-direction: row;
    }
}