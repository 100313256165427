
.content-container{
    max-width: 1024px;
    margin: auto;
    padding: 0 1rem;
}

.privacy-content{
    margin-bottom: 5rem;
}

.privacy-content h3{
    margin: 2.5rem 0;
}

.terms-and-conditions{
    margin-top: 2rem;
    margin-bottom: 5rem;
}

#text-content ul{
    margin-bottom: 1rem;
    padding-left: 2rem;
    list-style-type: initial;
    line-height: 1.5;
}

#text-content a{
    color: var(--primary-700);
    text-decoration: underline;
}