:root {

  --primary-900: #00031E;
  --primary-700: #0077B6;
  --primary-500: #00B4D8;
  --primary-300: #90E0EF;
  --primary-100: #CAF0F8;

  --gray-700: #212529;
  
  --white: #ffffff;
  --black: #000000;

  --text-color: var(--gray-900);

  --border-radius: 5px;
  --box-shadow:rgba(0, 0, 0, 0.04) 0px 3px 5px;
  --body-font: 'Roboto', 'san-serif';
  --heading-font: 'Oswald', 'sans-serif'
  
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,body{
  font-family: var(--body-font);
  color: var(--text-color);
}

/* body{
  background-image: url(/public/img/white-bg.webp);
  background-position:center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
} */

main{
  min-height: calc(100vh - 205px);
}

p{
  line-height: 1.5;
  font-size: 16px;
  margin-bottom: .8rem;
}

h1,h2,h3,h4,h5{
  font-family: var(--heading-font);
  line-height:1.2;
  font-weight: 500;
  margin-bottom: 1rem;
  color: var(--gray-900);
}

h1{
  font-size: 32px;
}
h2{
  font-size: 28px;
}
h3{
  font-size: 24px;
}
h4{
  font-size: 21px;
}
h5{
  font-size: 18px;
}

a{
  text-decoration: none;
  color: inherit;
}
label,
button,
input,
textarea
{
  font-family: var(--body-font);
}

label{
  margin-bottom: 0.25rem;
  display: block;
}

input,textarea{
  font-size: 16px;
  width: 100%;
  padding: 0.8rem;
  outline: none;
  background: var(--white);
  border-radius: 5px;
  border: 1px solid #acacac;
  margin-bottom:1rem
}

img{
  max-width: 100%;
  height: auto;
}

ul{
  list-style-type: none;
  padding: 0;
}

.no-scroll{
  height: 100vh;
  overflow: hidden;
}

.container{
  max-width: 1440px;
  margin: auto;
  padding: 0 1rem;
}

.btn{
  text-wrap: nowrap;
  padding: .8rem 1.5rem;
  border: none;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
  font-family: 'Roboto', 'san-serif';
}

.page-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.page-header .content{
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.page-header .content h1{
  color: var(--primary-500);
  margin-bottom: .5rem;
}

.page-header .content p{
  max-width: 500px;
}

.page-header .content h4{
  color: rgb(37, 37, 37);
}

@media (min-width:768px) {

  h1{
    font-size: 48px;
  }
  h2{
    font-size: 32px;
  }
  h3{
    font-size: 28px;
  }
  h4{
    font-size: 24px;
  }
  h5{
    font-size: 18px;
  }
  
}


@media (min-width: 640px) {

 }

@media (min-width:768px) {


}

@media (min-width: 1024px) { 

 }

@media (min-width: 1280px) {  }
@media (min-width: 1536px) {  }