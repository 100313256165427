
.tab{
    max-width: 600px;
    margin: auto;
    margin-bottom: 4rem;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid var(--primary-700);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-link{
    width: 100%;
    padding: 1rem;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    border-right: 1px solid var(--primary-700);
}
.tab-link.active{
    background-color: var(--primary-700);
    color: var(--white);
}

.tab-link:last-of-type{
    border: none;
}

.meeting-point-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.meeting-point-heading p{
    margin-bottom: 1rem;
}

.meeting-point-heading a{
    color: var(--primary-700);
    text-decoration: underline;
}

.meeting-point-heading img{
    margin: 1.5rem 0;
    width: 100%;
    max-width: 450px;
    border-radius: 15px;
    border: 2px solid var(--primary-700);
}

.map iframe{
    width: 100%;
}
.games-grid{
    margin: auto;
    max-width: 1200px;
    margin-bottom: 4rem;
    flex-wrap: wrap;
    display: flex;
    gap: 2px;
}

.game{
    flex-basis: 100%;
    position: relative;
    height: 450px;
}

.game > img{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position:center;
    width: 100%;
    height: 100%;
}

.game-content{
    z-index: 2;
    width: 100%;
    height: 100%;
    flex-direction: column;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #0000006c;
}

.game-content h2{
    font-weight: 500;
    font-family: var(--body-font);
}

.game-content > img{
    filter: brightness(0) invert(1);
    height: 105px;
    margin-bottom: 2rem;
}

.game-content .read-more{
    border: 1px solid var(--white);
    background: none;
    color: var(--white);
}

.game-pop-up-modal{
    visibility: hidden;
    opacity: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 200ms ease-in;
}

.game-pop-up-modal .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #0000006c;
}

.game-pop-up-modal .modal-container{
    transform: translateY(-90px);
    display: flex;
    flex-direction: column;
    margin: auto;
    position: relative;
    background-color: var(--white);
    border-radius: 10px;
    width: 100%;
    height: 90vh;
    margin-top: 2rem;
    margin-bottom: 2rem;
    max-width: 550px;
    transition: all 300ms ease-in-out;
}

.game-pop-up-modal.open{
    opacity: 1;
    visibility: visible;
}
.game-pop-up-modal.open .modal-container{
    transform: none;
}

.game-pop-up-modal .modal-container >*{
    padding: 1.25rem;
}

.game-pop-up-modal .modal-header{
    border-bottom: 1px solid gray;
}

.game-pop-up-modal .modal-header h4{
    margin: 0;
    font-family: var(--body-font);
}

.game-pop-up-modal .modal-content{
    flex: 1;
    overflow: auto;
}

.game-pop-up-modal .modal-footer .close-modal{
    color: var(--white);
    background-color: var(--primary-300);
}

.game-pop-up-modal .modal-footer{
    border-top: 1px solid gray;
    display: flex;
    justify-content: flex-end;
}


.karaoke{
    max-width: 640px;
    margin: auto;
    margin-bottom: 5rem;
    background-color:#f8f8f8;
}

.karaoke-heading{
    margin: 0 1rem;
    margin-top: 2rem;
    border-bottom: 1px solid black;
}

.karaoke-heading h2{
    text-align: center;
    font-weight: 300;
    color: var(--primary-700);
}

.karaoke-content{
    padding: 2rem 1.75rem;
}
.karaoke-content h5{
    font-family: var(--body-font);
    font-size: 20px;
    margin-bottom: .5rem;
}

.featured-images{
    display: flex;
    flex-direction: column;
    column-gap: 1rem;
    padding: 0 1.75rem;
    padding-bottom: 2rem;
}
.featured-images img{
    width: 100%;
    flex: 1;
}

@media (min-width:1024px) {
    .game{
        min-width: 500px;
        flex: 1;
    }

    .karaoke{
        max-width: 1200px;
    }

    .featured-images{
        flex-direction: row;
    }

    .featured-images img{
        width: 45%;
    }
}