
nav.site-nav{
    top: 0;
    position: sticky;
    width: 100%;
    height: 100%;
    background-color:var(--white);
    color:var(--gray-700);
    z-index: 999;
    transition: all 0.4s ease-in-out;
}

.navbar{
    width: 100%;
    flex-wrap: wrap;
    padding: .8rem 0;
    column-gap: 1.75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.navbar .brand img{
    width: 90px;
    height:auto;
}

.nav-toggler{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: 1px solid lightgray;
    padding: 1.3rem 1.7rem;
    /* color: var(--white); */
    font-size: 20px;
    border-radius: 5px;
}

.nav-toggler svg{
    position: absolute;
    z-index: -1;
}
.navbar-nav{
    flex-basis: 100%;
    flex-grow: 1;
}
.navbar-nav.collapse {
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-in;
}
  
.navbar-nav.collapse.open {
    height: 100%;
    overflow: visible; 
}

.book-btn{
    background-color: #DC3545;
    color: white;
    font-size: 15px;
}
.navbar-nav .nav-list{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    column-gap: 2rem;
    row-gap: 1rem;
}

.nav-list .nav-item{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 17px;
    transition: all 200ms ease;
}

.nav-list .nav-item:hover{
    color: var(--primary-700);
}
.nav-list .nav-item .nav-link{
    display: flex;
    align-items: center;
    gap: .5rem;
}

.nav-list .nav-item .nav-link svg{
    font-size: 18px;
}

.nav-list .nav-item .nav-link:hover{
    transition: all 100ms ease-in;
    color: var(--primary-color);
}

.widget-container{
    background-color: #00000057;
    z-index: -9999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    overflow: auto;
}

.widget-container .widget-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.widget-container .widget-iframe{
    position: relative;
    top: 50px;
}

.close-icon{
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 20px;
}
.widget-open{
    z-index: 9999;
    visibility: visible;
}

@media (min-width: 640px) {

}

@media (min-width:768px) {

}

@media (min-width: 1024px) { 
    .navbar .brand img{
        width: 100px;
        height:auto;
    }
    .nav-toggler{
        display: none;
    }

    .navbar-nav.collapse{
        flex-basis: unset;
        height: 100%;
    
    }

    .navbar-nav .nav-list{
        margin: 0;
        justify-content: flex-end;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
}

@media (min-width: 1280px) {  }
@media (min-width: 1536px) {  }