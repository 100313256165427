
.page-header{
    padding: 0 1rem;
}

.team{
    margin-bottom: 4rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.5rem;
    row-gap: 1.75rem;
    justify-content: center;
    align-items: center;
}

.team .member{
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    width: 100%;
    min-width: 290px;
    height: 600px;
    border: 2px solid #000000;
}

.team .member .member-image{
    z-index: 1;
    position: absolute;
    object-fit: cover;
    object-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.team .member .member-details{
    opacity: 0;
    z-index: 2;
    position: absolute;
    padding: .5rem;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000049;
    backdrop-filter: blur(1px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    transition: all 100ms ease-in;

}

.team .member .member-details p{
    font-size: 15px;
}

.team .member .member-details .wrapper{
    transform: translateY(50px);
    transition: all 200ms ease-in-out;

}

.team .member:hover .member-details{
    opacity: 1;
}

.team .member:hover .member-details .wrapper{
    transform: none;
}

@media (min-width:768px) {
    .team .member{
        flex-basis: 33.33%;
        height: 470px;
    }
}

@media (min-width:1024px) {
    .team .member{
        flex-basis: 25%;
    }
}