

.meeting-points{
    display: flex;
    flex-direction: column;
    row-gap: 4rem;
    column-gap: 2rem;

}

.meeting-point-heading img{
    height: 300px;
}
.meeting-points >*{
    flex: 1;
}

@media (min-width:960px) {
    .meeting-points{
        flex-direction: row;
    }
}