footer{
    color: white;
    padding: 2rem 0;
    background-color: #262626;
}
.footer-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.footer-container >*{
    font-size: 20px;
    font-family: 'Oswald', 'san-serif';
}
.footer-container ul{
    display: flex;
    column-gap: 1rem;
}
.footer-container ul a{
    text-decoration: underline;
}
.footer-container ul li{
    position: relative;
}
.footer-container ul>:first-child::after{
    position: absolute;
    content: '';
    display: block;
    height: 20px;
    background-color: white;
    width: 2px;
    top: 8px;
    bottom: 0;
    right: -0.5rem;

}