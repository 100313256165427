
.faqs{
    max-width: 640px;
    margin: auto;
    margin-bottom: 5rem;
    padding: 0 1rem;
}

.faq .question{
    width: 100%;
    margin-bottom: 1px;
    border-radius: 0;
    font-size: 18px;
    font-weight: 500;
    color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .8rem;
    background-color: rgba(0, 0, 0, 0.479);
    text-transform: uppercase;
    font-family: var(--heading-font);
}
.faq .question svg{
    font-size: 28px;
}
.faq.open .question svg{
    transform: rotate(180deg);
}

.faq .answer{
    padding: 1rem;
    transition: all 200ms ease-in;
}

@media (min-width:1024px) {
    .faqs{
        max-width: 1200px;
    }
}