
.floating-button{
    z-index: 999;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--primary-700);
    color: white;
    font-size: 32px;
    position: fixed;
    bottom: 3.5rem;
    right: 1rem;
}