
.section-heading{
    padding: 1.25rem 1rem;
    background-color: var(--primary-500);
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-heading h2{
    margin: 0;
    font-family: var(--heading-font);
    font-weight: 600;
    color: var(--white);
    text-align: center;
}

@media screen and (min-width:768px) {
    .section-heading{
        padding: 1.75rem 1rem;
    }
}